import React from 'react';
import { useIntl, navigate, Link } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';
import withLocale from 'hoc/withLocale';
import { useLocation } from '@reach/router';
import Social from 'components/Social/Social';
import {
  StyledFooter,
  StyledContainer,
  StyledWrapper,
  StyledMain,
  StyledFooterLinks,
  StyledFooterLinksList,
  StyledFooterLink,
  StyledTitle,
  StyledFooterContact,
  StyledFooterContactList,
  StyledFooterContactItem,
  StyledCopyrightsText,
} from './FooterStyles';

const uuidv4 = require('uuid/v4');

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const Footer = ({ currentLocale }) => {
  const location = useLocation();
  const intl = useIntl();

  const handleLinkAndScroll = elem => {
    if (location.pathname === '/pl/' || location.pathname === '/en/') {
      window.location.hash = elem;
      scroller.scrollTo(elem, {
        duration: 1000,
        delay: 100,
        smooth: true,
        hash: elem,
      });
    } else {
      navigate(`/`, { state: { scrollToSection: elem }, hash: { elem } });
    }
  };

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { regex: "/footer/" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 120) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      dataPl: strapiFooter(locale: { eq: "pl" }) {
        additional_info
        address
        email
        facebook
        instagram
        linkedin
        name
        nip
        phone
        phone_second
        regon
        youtube
      }
      dataEn: strapiFooter(locale: { eq: "en" }) {
        additional_info
        address
        email
        facebook
        instagram
        linkedin
        name
        nip
        phone
        phone_second
        regon
        youtube
      }
      navPl: strapiNavigation(locale: { eq: "pl" }) {
        footer_nav
      }
      navEn: strapiNavigation(locale: { eq: "en" }) {
        footer_nav
      }
    }
  `);

  let query;
  if (currentLocale === 'pl') query = data.dataPl;
  else if (currentLocale === 'en') query = data.dataEn;

  const {
    additional_info: addInfo,
    address,
    nip,
    name,
    phone,
    phone_second: phoneSecond,
    regon,
    facebook,
    instagram,
    linkedin,
    youtube,
  } = query;

  const socialsData = [
    {
      id: 1,
      name: 'Linkedin',
      url: linkedin,
    },
    {
      id: 2,
      name: 'Facebook',
      url: facebook,
    },
    {
      id: 3,
      name: 'Youtube',
      url: youtube,
    },
    {
      id: 4,
      name: 'Instagram',
      url: instagram,
    },
  ];

  let nav;
  if (currentLocale === 'pl') nav = data.navPl;
  else if (currentLocale === 'en') nav = data.navEn;

  const { footer_nav: footerNav } = nav;

  return (
    <StyledFooter>
      <StyledContainer>
        <StyledWrapper>
          <StyledMain>
            <StyledFooterLinks>
              <StyledTitle>
                {intl.formatMessage({ id: 'footer_sections' })}
              </StyledTitle>
              <StyledFooterLinksList>
                {footerNav.map(item => {
                  const { strapi_component: componentName } = item;
                  switch (componentName) {
                    case 'navigation.strona-dynamiczna': {
                      const { dynamic_page: dynamicPage } = item;
                      if (dynamicPage) {
                        return (
                          <StyledFooterLink key={uuidv4()}>
                            <Link to={`/${dynamicPage.slug}`}>
                              {dynamicPage.title}
                            </Link>
                          </StyledFooterLink>
                        );
                      }
                      return false;
                    }
                    case 'navigation.strona-statyczna': {
                      const { static_page: staticPage } = item;
                      if (staticPage) {
                        return (
                          <StyledFooterLink key={uuidv4()}>
                            <Link to={staticPage.url}>{staticPage.title}</Link>
                          </StyledFooterLink>
                        );
                      }
                      return false;
                    }
                    case 'navigation.sekcja': {
                      if (!item.all_section) {
                        return null;
                      }

                      return (
                        <StyledFooterLink
                          key={uuidv4()}
                          onClick={() =>
                            handleLinkAndScroll(
                              item.all_section.hash
                                ? `#${item.all_section.hash}`
                                : item.all_section.name
                            )
                          }
                        >
                          {item.all_section.name}
                        </StyledFooterLink>
                      );
                    }
                    default:
                      return false;
                  }
                })}
              </StyledFooterLinksList>
            </StyledFooterLinks>
            <StyledFooterContact>
              <StyledTitle>
                {intl.formatMessage({ id: 'footer_contact' })}
              </StyledTitle>
              <StyledFooterContactList>
                <StyledFooterContactItem>{name}</StyledFooterContactItem>
                <StyledFooterContactItem>{address}</StyledFooterContactItem>
                <StyledFooterContactItem>
                  {intl.formatMessage({ id: 'footer_nip' })} {nip},{' '}
                  {intl.formatMessage({ id: 'footer_regon' })} {regon}
                </StyledFooterContactItem>
                <StyledFooterContactItem>
                  {intl.formatMessage({ id: 'footer_phone' })} <b>{phone}</b>
                </StyledFooterContactItem>
                <StyledFooterContactItem>
                  {intl.formatMessage({ id: 'footer_phone' })}
                  <b>{phoneSecond}</b>
                </StyledFooterContactItem>
                <StyledFooterContactItem>{addInfo}</StyledFooterContactItem>
              </StyledFooterContactList>
              <Social socialsData={socialsData} small black />
            </StyledFooterContact>
          </StyledMain>
        </StyledWrapper>
      </StyledContainer>
      <StyledCopyrightsText>
        {intl.formatMessage({ id: 'copyright' })}
      </StyledCopyrightsText>
    </StyledFooter>
  );
};

export default withLocale(Footer);
